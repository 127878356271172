import React, { useEffect } from 'react'
import 'twin.macro'

import tw from 'twin.macro'

import SEO from 'components/shared/seo'
import { useStyles } from 'content-queries/mx/new/styles'
import fireEvent from 'context/tracking/events'
import { AutomaticPopup } from 'mx/automatic-popup'
import { Header, MainContent } from 'mx/components/new'
import { ContentCarousel } from 'mx/components/new/content-carousel'
import Layout from 'mx/layout'

const StylesPage = ({ path }: { path: string }) => {
  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Styles',
    })
  }, [])

  const { data } = useStyles()

  const [
    {
      node: {
        sectionModules: [hero, handleless, integrated, flat, shaker, banner],
      },
    },
  ] = data

  return (
    <Layout path={path}>
      <AutomaticPopup />
      <Header data={hero} />
      <ContentCarousel data={handleless} classname={tw`mt-20 lg:mt-32`} />
      <ContentCarousel data={integrated} classname={tw`mt-20 lg:mt-32`} />
      <ContentCarousel data={flat} classname={tw`mt-20 lg:mt-32`} />
      <ContentCarousel data={shaker} classname={tw`mt-20 lg:mt-32`} />
      <MainContent data={banner} centerCTA classname={tw`mt-20 lg:mt-32`} />
    </Layout>
  )
}

export default StylesPage

export const Head = () => {
  const { data } = useStyles()
  const [
    {
      node: {
        image,
        meta: { title, description },
      },
    },
  ] = data
  return (
    <SEO
      description={description}
      path="/styles"
      title={title}
      image={
        image.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src
      }
    />
  )
}
